import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { authKeySymbol } from './shared'
import { createSchemaTransform, stringToJSONSchema } from '../util'
import zod from 'zod'

const queryKey = [authKeySymbol, 'known-credentials']

export type StoredCredential = {
    credentialId: string
    user: { name: string; id: string; displayName?: string }
}

export const userSchema = stringToJSONSchema.transform(
    createSchemaTransform(
        zod.object({
            name: zod.string(),
            id: zod.string(),
            displayName: zod.string().optional(),
        }),
    ),
)

export function useDeleteKnownCredential(): [(credentialId: string) => Promise<void>, boolean] {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: async (credentialId: string) => {
            localStorage.removeItem(`storedCredential.${credentialId}`)
        },
        onSettled: () => {
            return queryClient.refetchQueries({
                queryKey,
            })
        },
    })

    return [mutation.mutateAsync, mutation.isPending]
}

export function useKnownCredentials(): StoredCredential[] {
    const queryResult = useSuspenseQuery({
        queryKey,
        queryFn: () => {
            const readCredentials: StoredCredential[] = []
            for (let index = 0; index < localStorage.length; index++) {
                const storageKey = localStorage.key(index) ?? ''
                const storageKeyParts = storageKey.split('.')
                if (storageKeyParts.length === 2 && storageKeyParts[0] === 'storedCredential') {
                    const storageValue = localStorage.getItem(storageKey)
                    let user = userSchema.safeParse(storageValue)
                    if (user.success && user.data) {
                        readCredentials.push({
                            credentialId: storageKeyParts[1],
                            user: {
                                ...user.data,
                                displayName:
                                    user.data.displayName ??
                                    `${user.data.name} // ${storageKeyParts[1].substring(0, 5)}`,
                            },
                        })
                    }
                }
            }

            return readCredentials.sort((a, b) =>
                `${a.user.name} ${a.credentialId}`.localeCompare(`${b.user.name} ${b.credentialId}`),
            )
        },
        refetchOnWindowFocus: true,
        refetchInterval: 10_000,
    })

    return queryResult.data
}
