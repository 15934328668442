import { useSuspenseQuery } from '@tanstack/react-query'
import { useCurrentUser } from '@base-app/library'
import zod from 'zod'
import { useMemo } from 'react'

const calendarSchema = zod.object({
    title: zod.string(),
    description: zod.string().nullable(),
    startTime: zod.coerce.date(),
    endTime: zod.coerce.date(),
})

export function Calendar() {
    const user = useCurrentUser()
    const calendarItems = useSuspenseQuery({
        queryKey: ['calendarItems'],
        queryFn: async ({ signal }) => {
            const data = await user.proxyClient.get({ signal }, 'GetCalendarItems')
            return zod.array(calendarSchema).parse(data)
        },
    })

    const dateFormatter = useMemo(
        () =>
            new Intl.DateTimeFormat('da', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            }),
        [],
    )

    return (
        <>
            <h1>The Calendar</h1>
            <ul>
                {calendarItems.data.map((item) => (
                    <li key={item.startTime.getTime()}>
                        <h2>{item.title}</h2>
                        <span>{dateFormatter.format(item.startTime)}</span> -{' '}
                        <span>{dateFormatter.format(item.endTime)}</span>
                        {item.description && <p>{item.description}</p>}
                    </li>
                ))}
            </ul>
        </>
    )
}
