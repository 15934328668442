import zod from 'zod'
import { jwtDecode } from 'jwt-decode'

export function createSchemaTransform<T extends zod.ZodRawShape>(targetSchema: zod.ZodObject<T>) {
    return (input: unknown, context: zod.RefinementCtx) => {
        try {
            return targetSchema.parse(input)
        } catch (reason) {
            if (!(reason instanceof zod.ZodError)) {
                throw reason
            }
            context.addIssue({
                code: 'custom',
                message: reason.message,
            })
            return zod.NEVER
        }
    }
}

export function base64EncodeURL(byteArray: ArrayBuffer) {
    return btoa(
        Array.from(new Uint8Array(byteArray))
            .map((byte) => {
                return String.fromCharCode(byte)
            })
            .join(''),
    )
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}

export function base64DecodeURL(b64urlstring: string) {
    return new Uint8Array(
        atob(b64urlstring.replace(/-/g, '+').replace(/_/g, '/'))
            .split('')
            .map((character) => {
                return character.charCodeAt(0)
            }),
    )
}

const literalSchema = zod.union([zod.string(), zod.number(), zod.boolean(), zod.null()])

type Literal = zod.infer<typeof literalSchema>

type Json = Literal | { [key: string]: Json } | Json[]

export const stringToJSONSchema = zod.string().transform((value, context): Json => {
    try {
        return JSON.parse(value)
    } catch (reason) {
        context.addIssue({ code: 'custom', message: 'Invalid JSON' })
        return zod.NEVER
    }
})

export const stringToAccessTokenSchema = zod.string().transform((value, context) => {
    try {
        return jwtDecode(value)
    } catch (reason) {
        context.addIssue({ code: 'custom', message: `Invalid token data: ${reason}` })
        return zod.NEVER
    }
})
