import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { appConfig } from './app-config'
import { useCurrentUser } from '@base-app/library'
export function useServiceWorkerRegistration(): ServiceWorkerRegistration | null {
    const { data } = useSuspenseQuery({
        queryKey: ['serviceWorker', 'registration'],
        queryFn: async () => (await navigator.serviceWorker.getRegistration()) ?? null,
    })

    return data
}

export function usePushNotificationSubscription() {
    const registration = useServiceWorkerRegistration()

    const { data } = useSuspenseQuery({
        queryKey: ['serviceWorker', 'pushSubscription', registration?.scope],
        queryFn: () => registration?.pushManager?.getSubscription() ?? null,
    })

    return data
}

export function useInstallations() {
    type Installation = {
        kind: string
        registrationId: string
        tags: string[]
    }
    const user = useCurrentUser()
    const httpClient = useMemo(() => {
        return user.createHttpClient(new URL('/api/', location.href).href)
    }, [user])

    const { data } = useSuspenseQuery({
        queryKey: ['serviceWorker', 'installations'],
        queryFn: ({ signal }) => {
            return httpClient.get<{ ok: boolean; data: Array<Installation> }>({ signal }, 'installations')
        },
        refetchInterval: 1500,
    })

    return data
}

type UseNotificationStatusReturn = {
    isBusy: boolean
    status: 'subscribed' | 'default' | 'denied' | 'granted' | 'error' | undefined
    subscription: PushSubscription | null
    subscribe: () => void
    unsubscribe: () => void
    unsubscribeAll: () => void
}
export function useNotificationStatus(): UseNotificationStatusReturn {
    const registration = useServiceWorkerRegistration()
    const subscription = usePushNotificationSubscription()
    const user = useCurrentUser()
    const queryClient = useQueryClient()

    const { data: status } = useQuery({
        queryKey: ['serviceWorker', 'notificationPermission'],
        queryFn: () => {
            try {
                return Notification.permission
            } catch (_) {
                return 'error'
            }
        },
        refetchInterval: 1000,
    })

    const httpClient = useMemo(() => {
        return user.createHttpClient(new URL('/api/', location.href).href)
    }, [user])

    const subscribe = useMutation({
        mutationFn: async () => {
            const abortController = new AbortController()
            const permission = await Notification.requestPermission()
            if (permission !== 'granted') {
                return false
            }

            const newSubscription = await registration?.pushManager?.subscribe({
                userVisibleOnly: true,
                applicationServerKey: appConfig.VAPID_PUBLIC_KEY,
            })
            if (newSubscription) {
                await httpClient
                    .post({ signal: abortController.signal }, 'register', {
                        ...newSubscription.toJSON(),
                        installationId: appConfig.installationId,
                    })
                    .catch(async (reason) => {
                        await newSubscription.unsubscribe()
                        throw reason
                    })
            }
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['serviceWorker'],
                exact: false,
                type: 'all',
            })
        },
    })

    const unsubscribe = useMutation({
        mutationFn: async () => {
            await subscription?.unsubscribe()
            await httpClient.post(new AbortController(), 'unregister', {
                installationId: appConfig.installationId,
            })
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['serviceWorker'],
                exact: false,
                type: 'all',
            })
        },
    })

    const unsubscribeAll = useMutation({
        mutationFn: async () => {
            await subscription?.unsubscribe()
            await httpClient.post(new AbortController(), 'unregister-all')
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['serviceWorker'],
                exact: false,
                type: 'all',
            })
        },
    })

    return {
        isBusy: subscribe.isPending || unsubscribe.isPending || unsubscribeAll.isPending,
        status: subscription ? 'subscribed' : status,
        subscription,
        subscribe: subscribe.mutate,
        unsubscribe: unsubscribe.mutate,
        unsubscribeAll: unsubscribeAll.mutate,
    } satisfies UseNotificationStatusReturn
}
