import { AuthProvider, useAuth } from 'react-oidc-context'
import { UserManager } from 'oidc-client-ts'
import { type PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { UserProvider } from '@base-app/library/_internal'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import zod from 'zod'
import { HttpClient } from '@base-app/library'
import { authKeySymbol, baseAppTokenSchema, type Profile } from './shared'
import { useCredentialLogin } from './credential-login'
import { createSchemaTransform, stringToAccessTokenSchema } from '../util'
import { appConfig } from '../app-config'

const accessTokenSchema = zod.object({
    accessToken: zod.string(),
    schoolCode: zod.string().optional(),
})

type AccessToken = zod.output<typeof accessTokenSchema>
const userManager = new UserManager({
    authority: appConfig.AUTH_AUTHORITY,
    client_id: appConfig.AUTH_CLIENT_ID,
    redirect_uri: appConfig.AUTH_REDIRECT_URL,
    scope: appConfig.AUTH_SCOPE,
})

const accessTokenKeySymbol = Symbol.for('access-token')

export function LoginProvider(props: PropsWithChildren) {
    const auth = useAuth()
    const queryClient = useQueryClient()
    const userRef = useRef(auth.user)

    const getBaseAccessToken = useCallback(async (): Promise<AccessToken | undefined> => {
        const user = userRef.current

        const sub = user?.profile.sub
        const iat = user?.profile.iat
        const accessToken = user?.access_token

        if (!sub || !iat || !accessToken) {
            return undefined
        }

        return await queryClient.ensureQueryData<AccessToken | undefined>({
            queryFn: async ({ signal }) => {
                const client = new HttpClient(appConfig.API_BASE_TOKEN_SERVICE, async () => accessToken, {})
                const data = await client.post({ signal }, 'BaseAppToken', {
                    schoolCode: 'A000001',
                })
                return accessTokenSchema.parse({
                    accessToken: data,
                })
            },
            gcTime: 60_000,
            staleTime: 10_000,
            queryKey: [authKeySymbol, accessTokenKeySymbol, sub, iat],
        })
    }, [queryClient])

    const credentialLogin = useCredentialLogin(getBaseAccessToken)

    useEffect(() => {
        userRef.current = auth.user
    })

    const getAccessToken = useCallback(async () => {
        if (credentialLogin.accessToken) {
            return {
                accessToken: credentialLogin.accessToken,
                schoolCode: undefined,
            }
        }

        return getBaseAccessToken()
    }, [getBaseAccessToken, credentialLogin.accessToken])

    const { data: profile } = useSuspenseQuery({
        queryKey: ['profile from token'],
        queryFn: async (): Promise<Profile | null> => {
            const accessToken = (await getAccessToken())?.accessToken
            if (accessToken) {
                try {
                    const tokenInfo = stringToAccessTokenSchema
                        .transform(createSchemaTransform(baseAppTokenSchema))
                        .parse(accessToken)
                    return {
                        age: tokenInfo['https://data.gov.dk/model/core/eid/age'],
                        cprUuid: tokenInfo['https://data.gov.dk/model/core/eid/cprUuid'] ?? '',
                        cprNumber: tokenInfo['https://data.gov.dk/model/core/eid/cprNumber'],
                        firstName: tokenInfo['https://data.gov.dk/model/core/eid/firstName'],
                        fullName: tokenInfo['https://data.gov.dk/model/core/eid/fullName'],
                        lastName: tokenInfo['https://data.gov.dk/model/core/eid/lastName'],
                        authenticationSource: tokenInfo['authentication-source'],
                    }
                } catch (reason) {
                    if (reason instanceof zod.ZodError) {
                        // eslint-disable-next-line no-console
                        console.log(reason.message)
                    }
                    throw reason
                }
            }
            return null
        },
        refetchInterval: ({ state }) => {
            if (state.error) {
                // eslint-disable-next-line no-console
                console.log(state.error)
                return 10_000
            }
            if (state.data === null) {
                return 100 * Math.ceil(state.dataUpdateCount / 100)
            }

            return 10_000
        },
    })

    const isAuthLoading = auth.isLoading || credentialLogin.isLoading
    const isAuthenticated = auth.isAuthenticated || credentialLogin.accessToken !== undefined

    const providerProps = {
        getAccessToken,
        isLoading: isAuthLoading || (auth.user?.access_token !== undefined && !profile),
        onRequestLogin: auth.signinRedirect,
        credentialLogin: credentialLogin,
    } as const

    if (isAuthenticated && profile) {
        return (
            <UserProvider
                appConfig={appConfig}
                id={profile.cprUuid}
                name={profile?.fullName}
                profile={profile}
                onLogout={async () => {
                    if (credentialLogin.accessToken) {
                        await credentialLogin.logout()
                    }
                    if (auth.isAuthenticated) {
                        await auth.signoutRedirect({
                            post_logout_redirect_uri: userManager.settings.redirect_uri,
                            extraQueryParams: {
                                client_id: userManager.settings.client_id,
                            },
                        })
                    }
                }}
                isAuthenticated={isAuthenticated}
                {...providerProps}
            >
                {props.children}
            </UserProvider>
        )
    }

    return (
        <UserProvider
            appConfig={appConfig}
            name="Anon"
            id="00000000-0000-0000-0000-000000000000"
            isAuthenticated={false}
            {...providerProps}
        >
            {props.children}
        </UserProvider>
    )
}

export function AuthWrapper({ children }: PropsWithChildren) {
    return (
        <AuthProvider
            userManager={userManager}
            onSigninCallback={() => {
                const newUrl = new URL(document.location.href)
                newUrl.searchParams.delete('code')
                newUrl.searchParams.delete('state')
                if (document.location.href !== newUrl.href) {
                    document.location.replace(newUrl)
                }
            }}
        >
            <LoginProvider>{children}</LoginProvider>
        </AuthProvider>
    )
}
