import { useCallback, useEffect, useState } from 'react'
import { useCurrentUser } from '@base-app/library'
import { useDeleteKnownCredential, useKnownCredentials } from '../auth/use-known-credentials'

export function Remember() {
    const user = useCurrentUser()

    const storedCredentials = useKnownCredentials()
    const [deleteCredential, isDeletePending] = useDeleteKnownCredential()

    const [rememberPhase, setRememberPhase] = useState<'none' | 'created' | 'creating' | 'error' | 'not-allowed'>(
        'none',
    )
    const onGetCredential = useCallback(async () => {
        setRememberPhase('creating')
        try {
            await user.credentialLogin?.create({
                name: user.name,
                id: user.id,
                displayName: `${user.name} #${storedCredentials.length + 1}`,
            })
            setRememberPhase('created')
        } catch (reason) {
            if (reason instanceof DOMException) {
                switch (reason.name) {
                    case 'NotAllowedError': {
                        setRememberPhase('not-allowed')
                        return
                    }
                    default: {
                        // eslint-disable-next-line no-console
                        console.log('DOMException', { name: reason.name, reason })
                    }
                }
            }

            setRememberPhase('error')
        }
    }, [user, storedCredentials])

    useEffect(() => {
        if (rememberPhase === 'none' || rememberPhase === 'creating') {
            return
        }

        const timeout = setTimeout(() => {
            setRememberPhase('none')
        }, 120_000)

        return () => {
            clearTimeout(timeout)
        }
    }, [rememberPhase])

    return (
        <div>
            <h2>Remember me</h2>
            {user.profile?.authenticationSource !== 'PassKey' ? (
                <button
                    disabled={rememberPhase === 'creating'}
                    onClick={onGetCredential}
                    type="submit"
                >
                    {storedCredentials.length > 0 ? 'Add another PassKey' : 'Create PassKey'}
                </button>
            ) : (
                <p>New PassKeys can only be created when not already authenticated with a PassKey</p>
            )}
            {rememberPhase === 'not-allowed' ? (
                <p>
                    Attempt to create PassKey was blocked by the browser. Either a security policy prevented it from
                    being created, or you cancelled the request.
                </p>
            ) : null}
            {rememberPhase === 'error' ? (
                <p>Oops, an unknown error occurred. Maybe reload the page and try again.</p>
            ) : null}
            {rememberPhase === 'creating' ? (
                <p>
                    Creating PassKey. Remember that cryptography is complicated, so it may take a short while before
                    anything happens. Please follow the instructions on your screen.
                </p>
            ) : null}
            {rememberPhase === 'created' ? <p>Done 😸</p> : null}
            <h3>Saved credentials</h3>
            <ul>
                {storedCredentials.map((item) => (
                    <li key={item.credentialId}>
                        {item.user.displayName}
                        <br />
                        <button
                            disabled={isDeletePending}
                            type="button"
                            onClick={() => deleteCredential(item.credentialId)}
                        >
                            Delete
                        </button>
                    </li>
                ))}
            </ul>
            <pre>{JSON.stringify(user, null, 4)}</pre>
        </div>
    )
}
