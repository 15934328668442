import { useQuery } from '@tanstack/react-query'
import { isValidElement, useEffect, useState } from 'react'

const checks = [
    {
        name: 'clipboard',
        getValue: () => (navigator.clipboard ? 'yes' : 'no'),
    },
    {
        name: 'navigator.language',
        getValue: () => navigator.language,
    },
    {
        name: 'serviceWorkerRegistration',
        getValue: async () => (await navigator.serviceWorker.getRegistration())?.scope,
    },
    {
        name: 'PushManager.permissionState',
        getValue: async () => Notification.permission,
    },
    {
        name: 'ServiceWorkerRegistration.pushManager.permissionState({userVisibleOnly: false})',
        getValue: async () =>
            (await navigator.serviceWorker.getRegistration())?.pushManager.permissionState({ userVisibleOnly: false }),
    },
    {
        name: 'ServiceWorkerRegistration.pushManager.permissionState({userVisibleOnly: true})',
        getValue: async () =>
            (await navigator.serviceWorker.getRegistration())?.pushManager.permissionState({ userVisibleOnly: true }),
    },
    {
        name: 'navigator.userAgent',
        getValue: () => navigator.userAgent,
    },
].map((item, index) => ({ ...item, id: index }))

function useChecks() {
    return useQuery({
        queryKey: ['debug', 'checks'],
        refetchOnMount: 'always',
        refetchInterval: 1500,
        queryFn: async () => {
            const data: Array<{ name: string; value: unknown; id: number }> = []
            for (const check of checks) {
                try {
                    data.push({
                        name: check.name,
                        id: check.id,
                        value: await check.getValue(),
                    })
                } catch (reason) {
                    data.push({
                        name: check.name,
                        id: check.id,
                        value: (
                            <>
                                <strong>Error</strong>: {reason instanceof Error ? reason.message : reason}
                            </>
                        ),
                    })
                }
            }

            return data
        },
    })
}

function DebugList() {
    const checks = useChecks()
    return (
        <ul style={{ overflow: 'auto', wordWrap: 'break-word' }}>
            {checks.data ? (
                checks.data.map((item) => {
                    return (
                        <li key={item.id}>
                            <strong>{item.name}</strong>:{' '}
                            {isValidElement(item.value) ? item.value : JSON.stringify(item.value ?? typeof item.value)}
                        </li>
                    )
                })
            ) : (
                <li>
                    <strong>Checks loading</strong>
                </li>
            )}
        </ul>
    )
}

export function DebugInfo() {
    const [isRevealed, setIsRevealed] = useState<boolean>(Boolean(localStorage.getItem('debugInfo.isRevealed')))

    useEffect(() => {
        if (isRevealed) {
            localStorage.setItem('debugInfo.isRevealed', '1')
        } else {
            localStorage.removeItem('debugInfo.isRevealed')
        }
    }, [isRevealed])

    return (
        <div>
            {isRevealed ? (
                <>
                    <h2>Debug information</h2>
                    <DebugList />
                </>
            ) : null}
            <button
                style={{ fontSize: '0.8rem' }}
                onClick={() => setIsRevealed(!isRevealed)}
                type="button"
            >
                {isRevealed ? 'Hide debug info' : 'Show debug info'}
            </button>
        </div>
    )
}
