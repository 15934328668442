import type { RegistrationHook } from '@base-app/library'
import { HookContextProvider } from '@base-app/library/_internal'
import { QueryClient, QueryClientProvider, useQueries } from '@tanstack/react-query'
import { type PropsWithChildren, type ReactNode, Suspense, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { appConfig } from './app-config'
import { AppShell } from './app-shell'
import { AuthWrapper } from './auth/auth-wrapper'
import { GlobalErrorFallback } from './globalErrorFallback'
import { type BeforeInstallPromptEvent, InstallEventProvider } from './install-button'
import { Calendar } from './pages/calendar'
import { Home } from './pages/home'
import { Remember } from './pages/remember'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            throwOnError: true,
            retry: false,
        },
    },
})

function createRouter(hooks: RegistrationHook[] = []) {
    const additionalMenuItems = hooks
        .filter((hook) => hook.type === 'menu')
        .map((hook) => {
            return {
                to: '/hook-' + hook.id,
                label: hook.title,
                element: <HookContextProvider {...hook} />,
            }
        })

    const shellElement = <AppShell additionalMenuItems={additionalMenuItems} />

    const hookRoutes = additionalMenuItems.map((hook) => {
        return {
            element: shellElement,
            path: hook.to,
            children: [
                {
                    index: true,
                    element: hook.element,
                },
            ],
        }
    })

    return createBrowserRouter([
        {
            path: '/*',
            element: shellElement,
            children: [
                {
                    element: <Home />,
                    index: true,
                },
                {
                    element: <Remember />,
                    path: 'remember',
                },
                {
                    element: <Calendar />,
                    path: 'calendar',
                },
            ],
        },
        ...hookRoutes,
    ])
}

type ChildWrapper = (children: ReactNode) => ReactNode
const modules: string[] = [...appConfig.plugins]

export function App({ installEvent }: PropsWithChildren<{ installEvent?: BeforeInstallPromptEvent }>) {
    const hooks = useQueries(
        {
            queries: modules.map((modulePath) => {
                return {
                    queryKey: ['module', modulePath],
                    queryFn: async () => {
                        const { config } = await import(modulePath).catch((reason) => {
                            // eslint-disable-next-line no-console
                            console.error(reason)
                            return { config: { hooks: [] } }
                        })
                        return config
                    },
                }
            }),
            combine: (results) => {
                if (results.some((result) => result.isPending)) {
                    return []
                }
                return results.flatMap((config) => config.data.hooks)
            },
        },
        queryClient,
    )

    const [router, setRouter] = useState(createRouter)

    useEffect(() => {
        if (hooks.length === 0) {
            return
        }
        setRouter(createRouter(hooks))
    }, [hooks])

    return (
        [
            (children) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>,
            (children) => <ErrorBoundary fallback={<GlobalErrorFallback />}>{children}</ErrorBoundary>,
            (children) => <InstallEventProvider value={installEvent}>{children}</InstallEventProvider>,
            (children) => <Suspense fallback={<p>Global Loading...</p>}>{children}</Suspense>,
            (children) => <AuthWrapper>{children}</AuthWrapper>,
            (children) => <Suspense fallback={<p>Out of appshell loading...</p>}>{children}</Suspense>,
        ] satisfies ChildWrapper[]
    )
        .toReversed()
        .reduce((previous, currentWrapper) => currentWrapper(previous), <RouterProvider router={router} />)
}
