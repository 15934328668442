import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { useErrorBoundary, ErrorBoundaryContext } from 'react-error-boundary'
import { createStorageKey } from './auth/credential-login'
import { authKeySymbol } from './auth/shared'

function renderError(error: unknown) {
    if (typeof error === 'string') {
        return <i>{error}</i>
    }
    if (error instanceof Error) {
        return (
            <pre>
                <strong>{error.name}</strong>
                <br />
                {error.stack}
            </pre>
        )
    }

    return <i>Unknown error: {String(error)}</i>
}

export function GlobalErrorFallback() {
    const boundary = useErrorBoundary()
    const context = useContext(ErrorBoundaryContext)
    const queryClient = useQueryClient()

    return (
        <div>
            <h1>A global error occurred</h1>
            <p>This could be due to any number of reasons. You have the following options.</p>

            <div style={{ margin: '1rem 0' }}>{renderError(context?.error)}</div>

            <button
                type="button"
                onClick={() => document.location.reload()}
            >
                Reload the page
            </button>

            <button
                type="button"
                onClick={async () => {
                    localStorage.removeItem(createStorageKey())
                    await queryClient.invalidateQueries({ queryKey: [authKeySymbol], exact: false })
                    boundary.resetBoundary()
                }}
            >
                Clear authentication token
            </button>
        </div>
    )
}
