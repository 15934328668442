import { createContext, useContext, useEffect, useState } from 'react'

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a website to a home screen on mobile.
 */
export interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to choose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed'
        platform: string
    }>

    /**
     * Allows a developer to show the install-prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<{ outcome: string; platform: '' }>
}

const context = createContext<BeforeInstallPromptEvent | undefined>(undefined)
context.displayName = 'BeforeInstallPromptEvent'

export const InstallEventProvider = context.Provider

function setAbortableTimeout(handler: () => void, timeout: number, signal: AbortSignal) {
    const timeoutId = setTimeout(handler, timeout)
    signal.addEventListener('abort', () => {
        clearTimeout(timeoutId)
    })

    return timeoutId
}

export function InstallButton() {
    const installEvent = useContext(context)
    const [outcome, setOutcome] = useState<string | null>(null)

    useEffect(() => {
        const abortController = new AbortController()
        if (outcome === 'dismissed') {
            setAbortableTimeout(
                () => {
                    setOutcome(null)
                },
                3000,
                abortController.signal,
            )
        }

        return () => abortController.abort()
    }, [outcome])

    if (!installEvent) {
        return null
    }

    return (
        <button
            type="button"
            disabled={outcome === 'accepted'}
            onClick={async () => {
                setOutcome('Installing...')
                try {
                    const userChoice = await installEvent.prompt()
                    setOutcome(userChoice.outcome || 'Unknown outcome')
                } catch (reason) {
                    setOutcome(`Error: ${reason instanceof Error ? reason.message : reason}`)
                }
            }}
        >
            Add to Homescreen {outcome ? <i>{outcome}</i> : null}
        </button>
    )
}
